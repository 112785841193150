
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Service from "@/services/auth/AuthService";
import store from "@/store";
import router from "@/router";

interface Form {
  email: string;
}

const baseForm: Form = {
  email: "",
};

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const email = ref<string>("");
    const password = ref<string>("");
    const loading = ref<boolean>(false);
    const form = ref<Form>(baseForm);

    const submit = (): void => {
      loading.value = true;
      Service.recuperarSenha(form.value)
        .then(() => {
          showNotify({
            type: "positive",
            message: "E-mail de recuperação de senha enviado com sucesso",
          });
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao enviar e-mail de recuperação de senha",
          });
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      if (store.getters.isLoggedIn) {
        router.push("/");
      }
    });

    return {
      requiredField,
      email,
      password,
      loading,
      showNotify,
      submit,
      form,
    };
  },
});
